import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = props => (
  <Layout pathname={props.location.pathname}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage

export const Head = ({ location }) => (
  <SEO
    title="404: Not found"
    description="Page not found"
    pathname={location.pathname}
  />
)